import { t } from "i18next";
import light from "../image/equipmentIcons/light.svg";
import street_sound from "../image/equipmentIcons/street_sound.svg";
import energy_block from "../image/equipmentIcons/energy_block.svg";
import warm from "../image/equipmentIcons/warm.svg";
import smoke from "../image/equipmentIcons/smoke.svg";
import fire from "../image/equipmentIcons/fire.svg";
import manage from "../image/equipmentIcons/manage.svg";
import newDefault from "../image/equipmentIcons/newdefault.svg";
import fireIcon from "../image/equipmentIcons/fireicon.svg";
import detector_manual from "../image/equipmentIcons/detector_manual.svg";
import detector_combined from "../image/equipmentIcons/detector_combined.svg";

const ICONS = {
  18: {
    icon: street_sound,
    description: t("icon_description:sound"),
  },
  20: {
    icon: light,
    description: t("icon_description:light"),
  },
  19: {
    icon: light,
    description: t("icon_description:light"),
  },
  21: {
    icon: street_sound,
    description: t("icon_description:sound"),
  },
  22: {
    icon: light,
    description: t("icon_description:combo"),
  },
  23: {
    icon: street_sound,
    description: t("icon_description:street_sound"),
  },
  15: {
    icon: energy_block,
    description: t("icon_description:energy_block"),
  },
  24: {
    icon: energy_block,
    description: t("icon_description:energy_block"),
  },
  25: {
    icon: energy_block,
    description: t("icon_description:energy_block"),
  },
  2: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  12: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  13: {
    icon: warm,
    description: t("icon_description:warm"),
  },
  1: {
    icon: smoke,
    description: t("icon_description:smoke"),
  },
  3: {
    icon: fire,
    description: t("icon_description:fire"),
  },
  10: {
    icon: detector_manual,
    description: t("icon_description:hand"),
  },
  17: {
    icon: manage,
    description: t("icon_description:manage"),
  },
  14: {
    icon: manage,
    description: t("icon_description:manage"),
  },
  84: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  85: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  86: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  87: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  88: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  89: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  90: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  91: {
    icon: fireIcon,
    description: t("icon_description:extinguisher"),
  },
  5: {
    icon: detector_combined,
    description: t("icon_description:combo"),
  },
};

export const getEquipmentIcon = (typeId) => ICONS[typeId]?.icon || newDefault;
