import React, { useState, useEffect, useRef, useCallback, memo, useMemo } from "react";
import { Stage, Layer, Line, Group, Image, Rect, Text, Circle } from "react-konva";
import { useWatch } from "react-hook-form";
import { Box } from "@mui/material";

import { loadImg } from "../../../helpers/helper";
import { getParamsTextWithScale } from "./helpers";
import { ICON_SIZE } from "../../../constants";
import { getEquipmentIcon } from "../../../helpers/getEquipmentIcon";
import { useChartsIcons } from "../../pages/EquipmentDetails/hooks";

import alarm from "../../components/icons/alarm.svg";
import ModalFullScreen from "../../pages/Equipments/ImageSchema/ModalFullScreen";
import DonutChart from "../DonutChart";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";

const SchemaWithEquipmentsAddEquip = ({
  name,
  width,
  height,
  focusId,
  schemaImg,
  roomAddresses = [],
  setValue,
  openEditModal,
  control,
  draggable = false,
  withScrollbars,
  title,
  canvasRef = null,
  isPlan = false,
  xyGroup,
  widthWorkplace,
  heightWorkplace,
  imageRef,
  setscaleAddEquip,
  scaleAddEquip,
  setoffsetAddEquip,
  offsetAddEquip,
  scaleRoom,
  setxyGroup,
  fullscreen = false,
  setFullScreen,
  onRightClick,
}) => {
 // const canvasRefFF =  canvasRef ? canvasRef :useRef(null);
  const { equipmentsCoordinates: data } = useWatch({
    control,
  });
  const [image, setImage] = useState(null);
  const [icons, setIcons] = useState(null);
  const [iconsSize, seticonsSize] = useState({ width: 25, height: 25 });
  const [ChartSize, setChartSize] = useState({ width: 22, height: 22 });
  const [alarmSize, setalarmSize] = useState({ width: 17, height: 17 });
  const [isDraggingScrollbar, setIsDraggingScrollbar] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const { SCHEMA_CANVAS1 } = useAppSelector((state) => state.settings);
  const { setCanvasSize1 } = useAction();
  const stageRef = useRef(null);
  const [ChartsList, getSrcSVG] = useChartsIcons({
    Chart: DonutChart,
    equipments: data,
  });
  const shadowProps = {
    x: 12,
    y: 12,
    shadowColor: 'blue',
    shadowBlur: 50,
    shadowEnabled: true,
    shadowOpacity: 1,
    shadowOffset: {x: 2, y: 3}
  }
  useEffect(() => { 
    seticonsSize({ width: 25 / window.devicePixelRatio, height: 25 / window.devicePixelRatio }) 
    setalarmSize({ width: 17 / window.devicePixelRatio, height: 17 / window.devicePixelRatio })
    setChartSize({ width: 22 / window.devicePixelRatio, height: 22 / window.devicePixelRatio })
  }, [window.devicePixelRatio])
  const drawIcons = useCallback(async (data, height = SCHEMA_CANVAS1.HEIGHT, width = SCHEMA_CANVAS1.WIDTH) => {
    if (data?.length) {
      const response = await Promise.all(
        data.map(async ({ id, floorSchemeCoordinateX: x, floorSchemeCoordinateY: y, typeId, workingCorrectly, jobChart }, index) => {
          return loadImg(getEquipmentIcon(typeId)).then(async (icon) => {
            const scaleX = width / SCHEMA_CANVAS1.WIDTH;
            const scaleY = height / SCHEMA_CANVAS1.HEIGHT;
            icon.width = iconsSize.width// Math.min(Math.max(countEquip,8),12);
            icon.height = iconsSize.height //Math.min(Math.max(countEquip,8),12)
            
            //   console.log("icon",icon.width,icon.height,(scale-minScale))
            // icon.width = icon.width > 7 ? icon.width :7
            // icon.height = icon.height > 7 ? icon.height : 7
            const sizeIcon = icon.width;

                const iconWithChart = {
                  id,
                  x: x * scaleX,
                  y: y * scaleY,
                  img: icon,
                 
                };

                return  iconWithChart;
             
          });
        })
      );
      setIcons(response);
    } else {
      setIcons([]);
    }
  }, [SCHEMA_CANVAS1, getEquipmentIcon, getSrcSVG, loadImg]);

  const drawScheme = useCallback(async (schemaImg, height, width) => {
    await loadImg(schemaImg).then((image) => {
      setCanvasSize1({
        WIDTH: image.width,
        HEIGHT: image.height,
      });
      image.width = width;
      image.height = height;
      setImage(image);
    });
  }, [loadImg]);

  useEffect(() => {
    schemaImg && drawScheme(schemaImg, height, width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaImg, height, width]);
  useEffect(() => {
    if (data) {
      const newData = data?.filter(({ floorSchemeCoordinateX, floorSchemeCoordinateY }) =>
        floorSchemeCoordinateX !== null && floorSchemeCoordinateY !== null
      );
      SCHEMA_CANVAS1.WIDTH && drawIcons(newData, height, width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, height, width,SCHEMA_CANVAS1]);


  const handleMouseOver = () => {
    if (draggable) document.body.style.cursor = "pointer";
  };

  const handleMouseOut = () => {
    document.body.style.cursor = "default";
  };

  const handleDragEnd = (e, id) => {
    const node = e.target;
    console.log("node",node)
    const scaleX = SCHEMA_CANVAS1.WIDTH / width;
    const scaleY = SCHEMA_CANVAS1.HEIGHT / height;

    const requestData = data.map((item) => {
      return item.id === id
        ? {
          ...item,
          floorSchemeCoordinateX: node.x() * scaleX/scaleAddEquip,
          floorSchemeCoordinateY: node.y() * scaleY/scaleAddEquip,
        }
        : item;
    });

    setValue && setValue(name, requestData);
  };
  const findValueById = (dataArray, id, coor) => {
    const foundObject = dataArray?.find(item => item.id === id);
    return foundObject ? coor == "x" ? foundObject.x : foundObject.y : 0; // Возвращает значение, если объект найден, иначе возвращает null или другое значение по умолчанию
  };
  const findcoordById = (dataArray, id) => {
    const foundObject = xyGroup?.find(item => item.id === id);
    let resArray = dataArray
    if (foundObject)
      resArray = dataArray.map((item, index) => index % 2 === 0 ? item * foundObject.scaleX : item * foundObject.scaleY)
    return resArray; // Возвращает значение, если объект найден, иначе возвращает null или другое значение по умолчанию
  };
  const handleWheel = (e) => {
    if (!isPlan) return


    e.evt.preventDefault();
    const stage = stageRef.current;
    const canvas = canvasRef.current;
    let newScale = scaleAddEquip;
    if (e.evt.deltaY < 0) {
      newScale = scaleAddEquip <= 5 ? scaleAddEquip + 0.1 : scaleAddEquip; // Ограничение максимального зума (2 в данном случае)
    } else {
      newScale = scaleAddEquip >= 0.5 ? scaleAddEquip - 0.1 : scaleAddEquip; // Ограничение минимального зума (0.5 в данном случае)
    }
    setscaleAddEquip((prev) => newScale);

    const pointTo = {
      x: stage.getPointerPosition().x / scaleAddEquip - canvas.x() / scaleAddEquip,
      y: stage.getPointerPosition().y / scaleAddEquip - canvas.y() / scaleAddEquip,
    };
    //setoffsetAddEquip((prev) => stageposition)
    setoffsetAddEquip({
      x: -(pointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(pointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    });
    const transformData = stage.getTransform().m
    
    setTransformCanvasData({
      x: transformData[4],
      y: transformData[5],
    })
  };
  const hexToRgbA = (hex, alpha) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
  }
  const handleDragEndStage = (e) => {
    
      const stage = canvasRef.current;
      const coordinates = [-stage.x() / scaleAddEquip, -stage.y() / scaleAddEquip, (width - stage.x()) / scaleAddEquip, (height - stage.y()) / scaleAddEquip];
      setoffsetAddEquip({x:stage.x(),y:stage.y()})
    //  scrollRef.current.scrollTop = stage.y()
    //scrollRef.current.scrollLeft  = stage.x()
  }
  
  const handleDragMove = (e) => {
   /* const stage = CanvasRef.current;
    const newX = e.target.x();
    const newY = e.target.y();
  
    // Ограничиваем перемещение по горизонтали
    const maxX = 0;
    const minX = -(image.width * scaleAddEquip - width);
  
    // Ограничиваем перемещение по вертикали
    const maxY = 0;
    const minY = -(image.height * scaleAddEquip - height);
  
    // Если новые координаты выходят за границы, оставляем их на границе
    const clampedX = Math.min(Math.max(newX, minX), maxX);
    const clampedY = Math.min(Math.max(newY, minY), maxY);
  
    setTransformCanvasData({
      x: clampedX,
      y: clampedY,
    });*/
    const maxX = 0;
    const minX = -(image.width * scaleAddEquip - width);
    const maxY = 0;
    const minY = -(image.height * scaleAddEquip - height);
  
    const newX = e.target.x();
    const newY = e.target.y();
    const clampedX = Math.min(Math.max(newX, minX), maxX);
    const clampedY = Math.min(Math.max(newY, minY), maxY);
      const stage = canvasRef.current;
     // const coordinates = [-stage.x() / scaleAddEquip, -stage.y() / scaleAddEquip, (width - stage.x()) / scaleAddEquip, (height - stage.y()) / scaleAddEquip];
      //setoffsetAddEquip({x:stage.x(),y:stage.y()})
      scrollRef.current.scrollTop = -stage.y();
      scrollRef.current.scrollLeft  = -stage.x();

  }

  const [transformCanvasData, setTransformCanvasData] = useState({
    x: 0,
    y: 0
  })

  const handleScrollbarMouseDown = (e, direction) => {
    setIsDraggingScrollbar(direction);
    setDragOffset({
      x: e.clientX,
      y: e.clientY,
    });
  };

  // Функция для перемещения скроллбара
  const handleScrollbarMouseMove = (e) => {
    if (!isDraggingScrollbar) return;

    const stage = stageRef.current;
    let newX = stage.x();
    let newY = stage.y();

    if (isDraggingScrollbar === "horizontal") {
      const dx = e.clientX - dragOffset.x;
      newX -= dx / scaleAddEquip;
      setDragOffset({ ...dragOffset, x: e.clientX });
    } else if (isDraggingScrollbar === "vertical") {
      const dy = e.clientY - dragOffset.y;
      newY -= dy / scaleAddEquip;
      setDragOffset({ ...dragOffset, y: e.clientY });
    }

    // Ограничиваем перемещение по горизонтали
    const maxX = 0;
    const minX = -(image.width * scaleAddEquip - width);

    // Ограничиваем перемещение по вертикали
    const maxY = 0;
    const minY = -(image.height * scaleAddEquip - height);

    // Применяем clamp
    newX = Math.max(Math.min(newX, maxX), minX);
    newY = Math.max(Math.min(newY, maxY), minY);

    stage.position({ x: newX, y: newY });
    setTransformCanvasData({ x: newX, y: newY });
  };

  // Функция для завершения перемещения
  const handleScrollbarMouseUp = () => {
    setIsDraggingScrollbar(false);
  };

  useEffect(() => {
    if (isDraggingScrollbar) {
      window.addEventListener("mousemove", handleScrollbarMouseMove);
      window.addEventListener("mouseup", handleScrollbarMouseUp);
    } else {
      window.removeEventListener("mousemove", handleScrollbarMouseMove);
      window.removeEventListener("mouseup", handleScrollbarMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleScrollbarMouseMove);
      window.removeEventListener("mouseup", handleScrollbarMouseUp);
    };
  }, [isDraggingScrollbar]);

  

  /*const stageStyles = useMemo(() => {
    return { transform: `translate(${offsetAddEquip.x}px, ${offsetAddEquip.y}px)` };
  }, [offsetAddEquip]);*/
  const handleScroll = useCallback((event) => {
    const { scrollLeft, scrollTop } = event.currentTarget;
    console.log("scrollLeft, scrollTop ",scrollLeft, scrollTop )
    setoffsetAddEquip({ x: -scrollLeft, y: -scrollTop });

  }, []);
  const handleDragBound = useCallback((pos, currentImage) => {
    let newX = pos.x;
    let newY = pos.y;

    icons.forEach((image) => {
      if (image !== currentImage) {
        const dx = pos.x - image.x;
        const dy = pos.y - image.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < 50) {  // Задайте желаемое расстояние между изображениями
          newX = currentImage.x;
          newY = currentImage.y;
        }
      }
    });

    return { x: newX, y: newY };
  }, [icons]);
  
  return (
    <Box ref={scrollRef} sx={{ position: "relative",border:"2px solid black",width:widthWorkplace? widthWorkplace : width,height: heightWorkplace? heightWorkplace :height}}>{/*,overflow: 'auto'}}    onScroll={handleScroll} >*/}
      <ChartsList />

      {!!setFullScreen && (
        <ModalFullScreen
          open={fullscreen}
          setOpen={setFullScreen}
          title={title || ""}
          canvasRef={canvasRef.current?.canvas._canvas || null}
          SCHEMA_CANVAS={SCHEMA_CANVAS1}
        />
      )}
      {/*scaleAddEquip > 1 && withScrollbars && <div>
        <div 
          onMouseDown={(e) => handleScrollbarMouseDown(e, "vertical")}
          style={{
            position: 'absolute',
            right: 0,
            width: '15px',
            borderRadius: '5px',
            top: `${-(transformCanvasData.y) / scaleAddEquip}px`,
            height: `${(height / scaleAddEquip)}px`,
            backgroundColor: 'rgba(0,0,0,.6)',
            zIndex: 100
          }} 
        />

        <div
          onMouseDown={(e) => handleScrollbarMouseDown(e, "horizontal")}
          style={{
            position: 'absolute',
            left: `${-(transformCanvasData.x) / scaleAddEquip}px`,
            width: `${(width / scaleAddEquip)}px`,
            bottom: 0,
            height: '15px',
            borderRadius: '5px',
            backgroundColor: 'rgba(0,0,0,.6)',
            zIndex: 100
          }}
        />
      </div>*/}

      <Stage 
         width={ widthWorkplace? widthWorkplace : width} 
         height={ heightWorkplace? heightWorkplace :height}
        style={{}}
     
        ref={stageRef}
        perfectDrawEnabled={false}
        onMouseEnter={e => {
          const container = e.target.getStage().container();
          container.style.cursor = 'grab';

        }}
      
      >

        <Layer ref={canvasRef}
           draggable
        
        
           width={"100%"}
          height={"100%"}
          //  style={stageStyles}
            onWheel={handleWheel}
          // onDragMove={handleDragMove}
           onDragEnd={handleDragEndStage}
            dragBoundFunc={pos => {
              //   console.log("pos",pos)
              const widthPos = widthWorkplace? widthWorkplace : width
              const HeightPos = heightWorkplace? heightWorkplace :height
              const offsetX = widthPos - image.width * scaleAddEquip
              const offsetY = HeightPos - image.height * scaleAddEquip
              //  console.log("stage",pos,image.width*scale,offsetX)
   
              if (offsetX <= 0) {
                if (pos.x <= offsetX) pos.x = offsetX;
                if (pos.x > 0) pos.x = 0;
   
              } else {
                if (pos.x > offsetX) pos.x = offsetX;
                if (pos.x <= 0) pos.x = 0;
              }
   
              if (offsetY <= 0) {
                if (pos.y <= offsetY) pos.y = offsetY;
                if (pos.y > 0) pos.y = 0;
   
              } else {
                if (pos.y > offsetY) pos.y = offsetY;
                if (pos.y <= 0) pos.y = 0;
              }
              return pos;
            }}
   
            x={offsetAddEquip.x}
            y={offsetAddEquip.y}   
        >
          <Group
            scaleX={scaleAddEquip}
            scaleY={scaleAddEquip}
          >
          <Image ref={imageRef} image={image} x={0} y={0} />
          {roomAddresses?.map(({ id, coordinates, name, plumes, borderColor, color }, index) => (
            <Group key={id || index}
              x={findValueById(xyGroup, id, "x")}
              y={findValueById(xyGroup, id, "y")}
            >
              <Line
                points={findcoordById(coordinates, id)}
                stroke={borderColor ? borderColor : "red"}
                fill={color ? hexToRgbA(color, 0.5) : "#f1b8bc80"}
                strokeWidth={3/scaleAddEquip}
                closed={true}
              />
              <Text {...getParamsTextWithScale({ coordinates: findcoordById(coordinates, id), text: name, line: 0,scale: scaleAddEquip })} />
              <Text {...getParamsTextWithScale({ coordinates: findcoordById(coordinates, id), text: plumes[0]?.name || plumes[0], line: 1,scale: scaleAddEquip })} />
            </Group>
          ))}
          </Group>
          {!!icons?.length &&
            icons.map(({ id, x, y, img, chart, alarm }, index) => (
              <Group
                onContextMenu={(e) => {
                  e.evt.preventDefault();
                  onRightClick && onRightClick(e, { id, x, y, img, chart, alarm }, index)
                }}
                key={id || index}
                draggable={draggable}
                onDragEnd={(e) => handleDragEnd(e, id)}
                onMouseOut={handleMouseOut}
                onMouseOver={handleMouseOver}
                onDblClick={() => openEditModal(id)}
               
                onMouseEnter={e => {
                  const container = e.target.getStage().container();
                  container.style.cursor = 'pointer';

                }}
                onMouseLeave={e => {

                  const container = e.target.getStage().container();
                  container.style.cursor = 'default';

                }}
                x={x * scaleAddEquip}
                y={y * scaleAddEquip}
              >
                <Circle
                  {...(focusId === id ? shadowProps : {})}
                  width={focusId === id ? img.width+50 : img.width}
                  strokeWidth={focusId === id ? 3 : 0}
                  stroke={"red"}
                  height={focusId === id ? img.height+50 : img.height}
                  fill={focusId === id ?"rgba(57,57,242,0.4)" : "transparent"}

                />
                <Image image={img} x={0} y={0} onClick={() => { if (!isPlan) navigate(PATHS.GET_EQUIPMENT_DETAILS(id)) }} />
              </Group>
            ))}
        </Layer>
      </Stage>
      </Box>
  
  );
};

export default memo(SchemaWithEquipmentsAddEquip)