import { Contacts } from "../../../components/Contacts/Contacts";
import { MenuItemLink, MenuItemPopupHandler } from "./Components";

export const menuItems = [
  {
    id: 1,
    label: "label:popularQuestions",
    link: "https://irkut.online//#faq",
    Component: MenuItemLink,
  },
  {
    id: 2,
    label: "label:contacts",
    Popup: Contacts,
    Component: MenuItemPopupHandler,
  },
  {
    id: 3,
    label: "label:aboutSystem",
    link: "https://irkut.online/",
    Component: MenuItemLink,
  },
]