import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box, Typography, Button, Slider, CircularProgress } from "@mui/material";
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";


import { getEquipmentIcon } from "../../../../helpers/getEquipmentIcon";

import Droppable from "./Droppable";

import FormItem from "../../../components/FormItem";
import { formFields } from "../form-config";
import { useLazyGetFloorInfoSelectQuery } from "../../../../middleware/fireCompartmentApi";
import { useLazyGetEquipmentsCoordinatesQuery, useGetEquipmentNumbersQuery } from "../../../../middleware/equipmentsApi";
import MissingFloorSchemaForm from "./MissingFloorSchemaForm";
import { modalAddressConfig } from "../form-config";
import ModalRoomAddress from "../ModalRoomAddress";
import SchemaWithEquipmentsAddEquip from "../../../components/DrawCanvas/SchemaWithEquipmentsAddEquip";
import {
  getRoomAddressForRequest,
  getRoomAddressForScheme,
} from "../../../../helpers/helper";
import PoligonIcon from "../../../components/icons/PoligonIcon";
import ArrowCancelBack from "../../../components/icons/ArrowCancelBack";
import ArrowPrevNext from "../../../components/icons/ArrowPrevNext";
import { EditDrawingPlume } from "../../../components/DrawCanvas/EditCanvas";
import { DrawingPlume } from "../../../components/DrawCanvas/DrawingPlume";
import { useAppSelector } from "../../../../hooks/redux";

const ImageSchemeSimple = ({
  items,
  control,
  isLite,
  errors,
  setValue,
  getValues,
  resetField,
  isDrawPoligon,
  setIsDrawPoligon,
  openEditModal,
  addplume,
  scaleAddEquip,
  setisFocusID,
  isFocusID,
  setFocusId,
  setscaleAddEquip,
  screenRelatedWidth,
  screenRelatedHeight,
  scale,
  imageRef,
  canvasRef,
  customNewRoomAddresses,
  setCustomNewRoomAddresses,
  setFloorScheme,
  widthWorkplace,
  heightWorkplace,
  floorScheme,
  searchIntNum,
  setsearchIntNum,
  turnOnEdit,
  selectShape,
  isEditShape,
  selectedId,
  setTurnOnEdit,
  offsetAddEquip,
  xyGroup,
  setxyGroup,
  setoffsetAddEquip,
  objectId,
  focusId,
  onRightClick
}) => {
  const { t } = useTranslation();

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
  const {
    fireCompartmentId,
    equipmentsCount = 1,
    equipmentsCoordinates,
    name,
    floor: floor,

    workingCorrectly,
    equipmentTypeId,
    roomAddresses,
  } = useWatch({
    control,
  });

  const [remainder, setRemainder] = useState(+equipmentsCount);
  const [floorsOptions, setFloorsOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [titleCompartment, setTitleCompartment] = useState("");
  const [newRoomAddresses, setNewRoomAddresses] = useState([]);

  const [numberIndex, setnumberIndex] = useState(0);
  const [removedAddress, setRemovedAddress] = useState([]);
  const [fullscreen, setFullScreen] = useState(false);
  const [isFloorSelect, setIsFloorSelect] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { SCHEMA_CANVAS, openSidebar } = useAppSelector(state => state.settings);



  const $container = useRef(null);
  const [widthContainer, setWidthContainer] = useState(0);

  const [getFloorOptions] = useLazyGetFloorInfoSelectQuery();
  const [getEquipmentsCoordinates, { isFetching: isEquipmentsCoordinatesLoading }] = useLazyGetEquipmentsCoordinatesQuery();


  useEffect(() => {
    if (fireCompartmentId) {
      setTitleCompartment(JSON.parse(fireCompartmentId)?.name || "");
      const facFireCompSelectIdValue = fireCompartmentId
        ? JSON.parse(fireCompartmentId)?.id
        : "";
      (async function () {
        const res = await getFloorOptions(facFireCompSelectIdValue);
        if (res?.data?.length) {
          const data = structuredClone(res.data).sort(
            (a, b) => a.name - b.name
          );
          setValue(formFields.floor, data[0].value);
          setFloorsOptions(data);
        }
      })();
    }
  }, [fireCompartmentId, getFloorOptions, setValue]);


  const screenRelatedRoomAddresses = useMemo(
    () => {
      return getRoomAddressForScheme(roomAddresses, scale)
    }
    ,
    [roomAddresses, scale]
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    setIsFloorSelect(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainder, setRemainder]);

  useEffect(() => {
    console.log("floo",isFocusID,focusId,scale)
    if(isFocusID){
      if (focusId != null && focusId != '' ) {
        const equipFind = equipmentsCoordinates?.find((obj) => obj.id == focusId)
  
  
        if (equipFind) {
          setisFocusID(false)
          setscaleAddEquip(5)
          const scaleX = screenRelatedWidth / SCHEMA_CANVAS.WIDTH;
          const scaleY = screenRelatedHeight / SCHEMA_CANVAS.HEIGHT;
          console.log("equipFind", equipFind, scaleX, scaleY)
          setoffsetAddEquip({ x: -equipFind.floorSchemeCoordinateX * 5 * scaleX + widthWorkplace / 2, y: -equipFind.floorSchemeCoordinateY * 5 * scaleX + heightWorkplace / 2 })
        } else {
          setscaleAddEquip(1)
          setoffsetAddEquip({ x: 0, y: 0 })
        }
      } else {
        
      }
    }
  }, [scale,focusId]);

  useEffect(() => {

    if (!floor) {
      return;
    }

    (async function () {
      if (!isFocusID) {
        setscaleAddEquip(1)
        setoffsetAddEquip({ x: 0, y: 0 })
      }

      const res = await getEquipmentsCoordinates(`id=${floor}&returnEquipments=${true}&returnRooms=${true}&returnJobCharts=${false}`);
      if (res?.data) {
        const { equipmentCoordinates, floorSchemeFileLink, roomAddresses } =
          res.data;
        setFloorScheme(floorSchemeFileLink);
        setValue(formFields.roomAddresses, roomAddresses);

        const coordinates = equipmentCoordinates.map((item) => ({
          ...item,
          floorInfoId: floor,
        }));

        setValue(formFields.equipmentsCoordinates, coordinates);
        setTurnOnEdit(false);
        setIsDrawPoligon(false);
        setNewRoomAddresses([]);
        setRemovedAddress([]);

      }
      localStorage.setItem("currentAddEquipObject", JSON.stringify({ object: objectId, floor: floor }))
    })();
  }, [floor, getEquipmentsCoordinates, setValue, getValues]);

  useEffect(() => {
    if (equipmentsCoordinates && equipmentsCoordinates?.length) {
      const countNewIcons = equipmentsCoordinates.filter(({ id }) =>
        id.includes("new")
      )?.length;
      setRemainder(+equipmentsCount - countNewIcons);
    }
  }, [equipmentsCoordinates, equipmentsCount]);

  return (
    <>


      <Box ref={$container} className="schema-field" sx={{ marginLeft: "20px" }}>
        {/* <Box className="schema-field-menu">
            <Box className="schema-field-menu-form">
              {items?.map(({ id, name, label, input, expended, ...rest }) => (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  options={name === formFields.floor ? floorsOptions : []}
                  input={input}
                  disabled={turnOnEdit}
                  {...rest}
                />
              ))}
            </Box>
            <Box className="schema-field-menu-items">
              <Typography className="schema-field-menu-items-title">
                {t("title:icon_dnd")}
              </Typography>
              <Box className="schema-field-menu-items-row">
                <Draggable
                  id={"fire"}
                  type={"fire"}
                  className="schema-field-menu-items-row-icon"
                >
                  {remainder > 0 && (
                    <img
                      src={equipmentTypeId && getEquipmentIcon(JSON.parse(equipmentTypeId)?.id || '')}
                      alt={t(name)}
                    />
                  )}
                </Draggable>
                <Typography className="schema-field-menu-items-row-label">{`${remainder} из ${equipmentsCount},Имя: ${equipNumbers[numberIndex]}`}</Typography>
              </Box>
            </Box>
          </Box>
*/}

        {!isEquipmentsCoordinatesLoading && <Box className="schema-field-image">
          {floorScheme ? (
            addplume ? (
              <DrawingPlume
                isLite={isLite}
                setValue={setValue}
                width={screenRelatedWidth || 0}
                height={screenRelatedHeight || 0}
                control={control}
                schemaImg={floorScheme}
                isDrawPoligon={isDrawPoligon}
                setsearchIntNum={setsearchIntNum}
                searchIntNum={searchIntNum}
                roomAddresses={screenRelatedRoomAddresses}
                getValues={getValues}
                resetField={resetField}
                newRoomAddresses={customNewRoomAddresses || newRoomAddresses}
                setNewRoomAddresses={setCustomNewRoomAddresses || setNewRoomAddresses}
              />
            ) : turnOnEdit ?
              (
                <EditDrawingPlume

                  width={screenRelatedWidth || 0}
                  height={screenRelatedHeight || 0}
                  control={control}
                  facilityId={objectId}
                  schemaImg={floorScheme}
                  isDrawPoligon={isDrawPoligon}
                  isEditShape={isEditShape}
                  scaleRoom={scale}
                  setsearchIntNum={setsearchIntNum}
                  searchIntNum={searchIntNum}
                  roomAddresses={screenRelatedRoomAddresses}
                  getValues={getValues}
                  selectShape={selectShape}
                  xyGroup={xyGroup}
                  setxyGroup={setxyGroup}
                  selectedId={selectedId}
                  setValue={setValue}
                  resetField={resetField}
                  newRoomAddresses={newRoomAddresses}
                  setNewRoomAddresses={setNewRoomAddresses}
                />
              ) : (
                <SchemaWithEquipmentsAddEquip
                  withScrollbars
                  focusId={focusId}
                  onRightClick={onRightClick}
                  name={formFields.equipmentsCoordinates}
                  width={screenRelatedWidth || 0}
                  height={screenRelatedHeight || 0}
                  schemaImg={floorScheme}
                  xyGroup={xyGroup}
                  widthWorkplace={widthWorkplace}
                  heightWorkplace={heightWorkplace}
                  setxyGroup={setxyGroup}
                  scaleAddEquip={scaleAddEquip}
                  imageRef={imageRef}
                  openEditModal={openEditModal}
                  canvasRef={canvasRef}
                  setscaleAddEquip={setscaleAddEquip}
                  setoffsetAddEquip={setoffsetAddEquip}
                  offsetAddEquip={offsetAddEquip}
                  roomAddresses={getRoomAddressForScheme(
                    roomAddresses,
                    scale
                  )}
                  isPlan={true}
                  setValue={setValue}
                  control={control}
                  draggable={true}
                  title={titleCompartment}
                  fullscreen={fullscreen}
                  setFullScreen={setFullScreen}
                />
              )
          ) : (
            <MissingFloorSchemaForm
              floor={floor}
              setFloorScheme={setFloorScheme}
              width={screenRelatedWidth}
            />
          )}
        </Box> || <CircularProgress />}


      </Box>

      <ModalRoomAddress
        open={open}
        setOpen={setOpen}
        config={modalAddressConfig}
      />
    </>
  );

};

export default ImageSchemeSimple;
