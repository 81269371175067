import React, { useState, useEffect, useMemo } from "react";
import {
  Stage,
  Layer,
  Line,
  Circle,
  Group,
  Image,
  Rect,
  Text,
  Transformer
} from "react-konva";

import { Box } from "@mui/material";

import {
  loadImg,

} from "../../../helpers/helper";
import { getParamsTextWithScale } from "../../pages/Plume/ImageSchema/helpers";
import { formFields, modalAddressConfig } from "../../pages/Plume/form-config";

import { ModalRoomAddressEdit } from "../Modals/ModalRoomAddressEdit";
import { useAppSelector } from "../../../hooks/redux";

const emptyRectParams = {
  x: null,
  y: null,
  width: null,
  height: null,
};

const initialTransform = {
  x: 0,
  y: 0,
  active: false,
};

export const EditDrawingPlume = ({
  width,
  height,
  control,
  setValue,
  schemaImg,
  isDrawPoligon,
  isEditShape,
  roomAddresses,
  setsearchIntNum,
  searchIntNum,
  facilityId,
  getValues,
  resetField,
  newRoomAddresses,
  handleDeleteRoom,
  selectShape,
  selectedId,
  scaleRoom,
  scaleDrag,

  xyGroup,
  setxyGroup,
  setNewRoomAddresses,
}) => {

  const [image, setImage] = useState(null);
  const [transform, setTransform] = useState(initialTransform);

  const [selectedname, Setselectedname] = useState(null);
  const [selectedplume, Setselectedplume] = useState(null);
  const [selectedintNum, SetselectedintNum] = useState(null);
  const [transformerVisible, setTransformerVisible] = useState(false);

  const [Editopen, setEditopen] = useState(false);
  const [points, setPoints] = useState([]);

  const [rectParams, setRectParams] = useState(emptyRectParams);
  const { SCHEMA_CANVAS } = useAppSelector((state) => state.settings);

  const shapeRef = React.useRef();

  const trRef = React.useRef();
  const scale = useMemo(
    () => ({
      x: SCHEMA_CANVAS.WIDTH / width,
      y: SCHEMA_CANVAS.HEIGHT / height,
    }),
    [width, height, SCHEMA_CANVAS]
  );
  const screenRelatedNewRoomAddresses = useMemo(() => {
    return newRoomAddresses.map(({ coordinates, ...rest }) => ({
      ...rest,
      coordinates: coordinates.map((item, index) =>
        index % 2 ? item / scale.y : item / scale.x
      ),
    }));
  }, [scale, newRoomAddresses]);

  const style = {
    display: transform.active ? "block" : "none",
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  };
  useEffect(() => {
    schemaImg && drawScheme(schemaImg, height, width);
  }, [schemaImg, height, width]);

  const allRoomAddresses = useMemo(() => {

    return [
      ...roomAddresses?.filter(({ id }) => id),
      ...screenRelatedNewRoomAddresses,
    ];
  }, [roomAddresses, screenRelatedNewRoomAddresses]);

  const drawScheme = async (schemaImg, height, width) => {
    await loadImg(schemaImg, height, width).then((image) => setImage(image));
  };
  useEffect(() => {
    schemaImg && drawScheme(schemaImg, height, width);
  }, [schemaImg, height, width]);


  const handledbl = (index, nname, plumes,protectionObjectIntegrNumberAltId) => (e) => {
    if (isEditShape) {
      selectShape(index)
      Setselectedname(nname)
      Setselectedplume(plumes)
        setValue(formFields.plumes, plumes.find(el => typeof el === 'string') ? plumes.map(el => ({
          name: el
        })) : plumes)
      SetselectedintNum(protectionObjectIntegrNumberAltId)
      setEditopen(true);

    }

  }
  const handleModalEditCancel = (e) => {
    resetField(formFields.roomAddress);
    setEditopen(false);
  };
  const handleEdit = () => (e) => {
    let arr = []
    let arrnewRoom = []
    let isNew = false
  
    const plumes = getValues(formFields.plumes)
    getValues(formFields.roomAddresses).map((item, index) => {
      
      if (index == selectedId) {
        isNew = true
        arr.push({ id: item.id, name: selectedname, plumes, protectionObjectIntegrNumberAltId:selectedintNum, coordinates: item.coordinates })
      } else {
        arr.push(item)
      }
    })
  
    if (!isNew)
      newRoomAddresses.map((item, index) => {
        if (getValues(formFields.roomAddresses)?.length + index == selectedId) {
          arrnewRoom.push({ name: selectedname, plumes, protectionObjectIntegrNumberAltId:selectedintNum, coordinates: item.coordinates })
        } else {
          arrnewRoom.push(item)
        }
      });

    isNew ? setValue(formFields.roomAddresses, arr) : setNewRoomAddresses(arrnewRoom)
    setEditopen(false);
    setValue(formFields.plumes, [])
  };
  React.useEffect(() => {
    if (selectedId != null && isEditShape) {

      // we need to attach transformer manually


      trRef?.current?.nodes([shapeRef.current.children[selectedId + 1]]);
      //trRef?.current?.getLayer().batchDraw();
    }
  }, [selectedId]);



  const findValueById = (dataArray, id, coor) => {
    const foundObject = dataArray?.find(item => item.id === id);
    return foundObject ? coor == "x" ? foundObject.x : foundObject.y : 0; // Возвращает значение, если объект найден, иначе возвращает null или другое значение по умолчанию
  };
  const handleImageClick = () => {
    setTransformerVisible(false)
    selectShape(null)
  };
  const handleGroupClick = (index) => (e) => {
    setTransformerVisible(true);
    selectShape(index)
  };
  const findscaleById = (id) => {
    let scale = 1;
    const foundObject = xyGroup?.find(item => item.id === id);
    if(foundObject) {
      scale = foundObject.scaleX
    }
    return scale; // Возвращает значение, если объект найден, иначе возвращает null или другое значение по умолчанию
  };
  return (
    <Box sx={{ position: "relative", cursor: "crosshair" }}>
      <ModalRoomAddressEdit
        name={formFields.roomAddress}
        control={control}
        open={Editopen}
        facilityId={facilityId}
        handleEdit={handleEdit}
        selectedname={selectedname}
        Setselectedname={Setselectedname}
        selectedintNum={selectedintNum}
        SetselectedintNum={SetselectedintNum}
        searchIntNum={searchIntNum}
        setsearchIntNum={setsearchIntNum}
        selectedplume={selectedplume}
        Setselectedplume={Setselectedplume}
        config={modalAddressConfig}
        handleCancel={handleModalEditCancel}
      />
      <Stage
        width={width}
        height={height}
      >
        <Layer ref={shapeRef}>
          <Image onClick={handleImageClick} image={image} x={0} y={0} />
          <React.Fragment>
            {allRoomAddresses.map(
              ({ id, coordinates, name, plumes,protectionObjectIntegrNumberAltId }, index) =>

                !!coordinates?.length && (

                  <Group
                    draggable={isEditShape ? true : false}
                    onDblClick={handledbl(index, name, plumes,protectionObjectIntegrNumberAltId)}
                    onClick={handleGroupClick(index)}
                    key={id || index}
                    onTransformEnd={(e) => {
                      const node = e.currentTarget;
                      const scaleX = node.scaleX();
                      const scaleY = node.scaleY();
                      const rotation = node.rotation();
                      const width = node.width();
                      const height = node.height();
                      const x = node.x();
                      const y = node.y();
      
                      let arr = xyGroup || []
                          let i =0
                          let isOld = false
                          arr?.map((item)=>{
                            if (item.id == id){
                              arr[i] = {...arr[i],x:x,y:y,scaleX:scaleX,scaleY:scaleY,scaleRoom:scaleRoom}
                              isOld = true
                            }
                              
                            i++
                          })
                          if(!isOld)
                              arr.push({id:id,x:x,y:y,scaleX:scaleX,scaleY:scaleY,scaleRoom:scaleRoom})
                         
                          setxyGroup(arr)
                    }}
                    /* onTransformEnd={(e) => {
 
                       //  const line = shapeRef.current.children[selectedId + 1].current.findOne('.line')
                       // console.log("arr",line.points())
                       const points = e.target.children[0].points();
                       const scaleX = e.target.scaleX();
                       const scaleY = e.target.scaleY();
                       const x = e.target.x()
                       const y = e.target.y()
                      
                      // node.scaleX(1);
                      // node.scaleY(1);
 
                          let arr = xyGroup || []
                          let i =0
                          let isOld = false
                          arr?.map((item)=>{
                            if (item.id == id){
                              arr[i] = {...arr[i],x:x,y:y,scaleX:scaleX,scaleY:scaleY}
                              isOld = true
                            }
                              
                            i++
                          })
                          if(!isOld)
                              arr.push({id:id,x:x,y:y,scaleX:scaleX,scaleY:scaleY})
                          console.log("arr",arr)
                          setxyGroup(arr)
                       
                     }}*/
                    onDragEnd={(e) => {

                      let arr = xyGroup || []
                      let i = 0
                      let isOld = false
                      arr?.map((item) => {
                        if (item.id == id) {
                          arr[i] = { ...arr[i], x: e.target.x(), y: e.target.y() }
                          isOld = true
                        }

                        i++
                      })
                      if (!isOld)
                        arr.push({ id: id, x: e.target.x(), y: e.target.y(),scaleX:1,scaleY:1 })
              
                      setxyGroup(arr)
                    }}

                    onMouseEnter={e => {
                      if (isEditShape) {
                        const container = e.target.getStage().container();
                        container.style.cursor = 'pointer';
                      }

                    }}
                    onMouseLeave={e => {
                      // on mouse leave
                      const container = e.target.getStage().container();
                      container.style.cursor = 'crosshair';
                    }}
                    x={findValueById(xyGroup, id, "x")}
                    y={findValueById(xyGroup, id, "y")}
                  >
                    <Line
                      points={coordinates}
                      stroke={selectedId == index ? "red" : "gray"}
                      fill={selectedId == index ? "#f1b8bc80" : "rgba(128,128,128,0.5)"}
                      closed={true}


                    />
                    <Text
                      {...getParamsTextWithScale({ coordinates, text: name, line: 0,scale:findscaleById(id) })}
                    />
                    <Text
                      {...getParamsTextWithScale({coordinates, text: plumes[0]?.name || plumes[0], line: 1,scale:findscaleById(id) })}
                    />
                  </Group>

                )
            )}
            {transformerVisible && <Transformer
              ref={trRef}

              flipEnabled={false}
              rotateEnabled={false}
              resizeEnabled={true}
              
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                  return oldBox;
                }
                return newBox;
              }}
            />
            }
          </React.Fragment>
          <Group>
            {points?.map((point, index) => (
              <Circle
                key={index}
                x={point.x}
                y={point.y}
                radius={3}
                fill="red"
              />
            ))}

            <Rect
              {...rectParams}
              fill={"#f1b8bc80"}
              stroke={"red"}
              strokeWidth={2}
            />

          </Group>
        </Layer>
      </Stage>
    </Box>
  );
};
