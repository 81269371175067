import React from "react";
import {
    Box,
    CardMedia,
    Typography,
} from "@mui/material";

import newLogo from "../../../image/Лого_И_3.png";

const LogoIconSm = () => {
    return (
        <Box className="left_block">
            <Box className="left_block-logo">
                <CardMedia
                    component="img"
                    sx={{width:"100px"}}
                    image={newLogo}
                    alt="Иркут"
                />
            </Box>
        </Box>
    );
};

export default LogoIconSm;
