import React from "react";
import { t } from "i18next";

import { formFields } from "./form-config";
import { EquipmentKindSelectGrouping } from "../Equipments/Components";
import { ClickableSchemaCell, SelectSimpleObject, SelectSimpleFloor, ClickableEquipmentCell,SimpleTextWithTooltipCellDetailes } from "./Components";
import { StatusCell, SimpleHeader, SimpleTextCell } from "../TasksList/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import FormItem from "../../components/FormItem";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import DatePickerInbuild from "../../components/DatePickerInbuild";
import { SimpleHHeader } from "../TasksList/Components";

const statusOptions = [
  { value: "все", name: "все" },
  { value: "true", name: "Да" },
  { value: "false", name: "Нет" }

]
const lifeTimeDateOptions = [
  { value: "все", name: "все" },
  { value: "true", name: "Превышен" },
  { value: "false", name: "Не превышен" }
]

export const getColumns = ({
  control,
  columnsFromStorage,
  searchequipmentCount,
  setsearchequipmentCount,
  objectId,
  searchStandardName,
  setsearchStandardName,
  searchPeriodName,
  setsearchPeriodName,
  searchEquipmentName,
  setsearchEquipmentName,
  searchRegulationName,
  setsearchRegulationName,
  searchWorkDetailes,
  setsearchWorkDetailes,
  searchWorkscopeName,
  setsearchWorkscopeName,
}) =>
  [
    {
      id: formFields.protectionObjectName,
      numeric: false,
      enableSorting: false,
      size: "225px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.protectionObjectName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "200px" }}>
          <SimpleHeader name={t("tableHeader:protectionObjectName")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.protectionObjectId}`}

            sx={{ padding: "12px 0" }}
            width="200px"
            input={(props) => (
              <SelectSimpleObject
                {...props}
                objectId={objectId || ""}
                isAllExist={true}
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.equipmentKindName,
      numeric: false,
      enableSorting: false,
      size: 200,
      clickable: false,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.equipmentKindName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: (props) => (
        <>
          <SimpleHeader name={t("tableHeader:typeKindEquip")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.equipmentKindId}`}

            sx={{ padding: "12px 0", width: "200px" }}
            input={(props) => (
              <EquipmentKindSelectGrouping
                itemoption={{
                  id: "все",
                  name: t("options_name:everything"),
                }}
                useShortTitle={true}
                {...props}
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                height={"24px"}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.equipmentName,
      numeric: false,
      enableSorting: false,
      size: "225px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.name],
      RowCell: (props) => <ClickableEquipmentCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "200px" }}>
          <SimpleHeader name={t("tableHeader:equip_name")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.EquipmentName`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchEquipmentName}
                changeSearchValue={setsearchEquipmentName}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.equipmentCount,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.equipmentTypeName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:equip_count")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.equipmentCount`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchequipmentCount}
                changeSearchValue={setsearchequipmentCount}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.regulationName,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.regulationName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:equip_reglament")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.RegulationName`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchRegulationName}
                changeSearchValue={setsearchRegulationName}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.isCustom,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.regulationName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
       
         <>
         <Box sx={{ width: "120px" }}>
           <SimpleHeader name={t("tableHeader:isCustomReglament")} />
         </Box>
         <Box sx={{ height: "48px" }}></Box>
       </>
      ),
    },
    {
      id: formFields.workscopeName,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.workscopeName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:workScopeName")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.WorkscopeName`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchWorkscopeName}
                changeSearchValue={setsearchWorkscopeName}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.workRequirementsDetails,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: true,
      //isVisible: columnsFromStorage[formFields.workRequirementsDetails],
      RowCell: (props) => <SimpleTextWithTooltipCellDetailes {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:workScopeDetails")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.WorkRequirementsDetails`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchWorkDetailes}
                changeSearchValue={setsearchWorkDetailes}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.periodName,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      isVisible: true,
      // isVisible: columnsFromStorage[formFields.number],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:equip_period")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.PeriodName`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchPeriodName}
                changeSearchValue={setsearchPeriodName}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.regulationDescription,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      //isVisible: columnsFromStorage[formFields.standardParagraphName],
      isVisible: true,
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:description")} />
          <Box sx={{ height: "48px" }}></Box>
        </Box>
      ),
    },
    {
      id: formFields.standardName,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      //isVisible: columnsFromStorage[formFields.standardParagraphName],
      isVisible: true,
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:standardName")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.standardName`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={searchStandardName}
                changeSearchValue={setsearchStandardName}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.standardParagraphName,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      //isVisible: columnsFromStorage[formFields.standardParagraphName],
      isVisible: true,
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
       
        <>
        <Box sx={{ width: "120px" }}>
          <SimpleHeader name={t("tableHeader:standardParagraphName")} />
        </Box>
        <Box sx={{ height: "48px" }}></Box>
      </>
      ),
    },
    {
      id: formFields.techCard,
      numeric: false,
      enableSorting: false,
      size: "120px",
      borderHeader: true,
      // isVisible: columnsFromStorage[formFields.number],
      isVisible: true,
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <Box sx={{ width: "120px" }}>
            <SimpleHeader name={t("tableHeader:tech_card")} />
          </Box>
          <Box sx={{ height: "48px" }}></Box>
        </>

      ),
    },
    {
      id: formFields.edit,
      numeric: false,
      enableSorting: false,
      label: "",
      isVisible: true,
      size: 50,
      clickable: false,
    },
  ].filter(({ isVisible }) => isVisible);