import { getEquipmentsCoordinatesWithRoomId } from "../../../helpers/helper";

export const getRequestEditData = (data) => {
  const { id, workingCorrectly, equipmentsCoordinates,floor, roomAddresses=[],roomAddressId, isActive,name,plume,placement,integrationNumber,manufactureDate,installationDate,number } = data || {};
  const { floorSchemeCoordinateX, floorSchemeCoordinateY, typeId } =
    equipmentsCoordinates[0] || {};
  const placementEquip = placement == "Запотолочное пространство" ? 0 : placement == "Подпольное пространство" ? 1 : 2
  /*const { roomAddressId=null } = getEquipmentsCoordinatesWithRoomId({
    equipmentsCoordinates,
    rooms: roomAddresses, 
  })[0] || {};*/


  return {
    name,
    plume,
    integrationNumber:integrationNumber,
    manufactureDate:manufactureDate,
    installationDate:installationDate,
    id,
    floorInfoId:floor,
    placement:placementEquip,
    workingCorrectly,
    equipmentCoordinateX: floorSchemeCoordinateX,
    equipmentCoordinateY: floorSchemeCoordinateY,
    roomAddressId,
    typeId,
    isActive,
    number
  };
};