import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Box } from "@mui/material";
import { useWatch } from "react-hook-form";

import { useAppSelector } from "../../../../hooks/redux";
import { useAction } from "../../../../hooks/actions";
import { formFields } from "../form-config";
import { getRoomAddressForScheme,loadImg } from "../../../../helpers/helper";

import { MissingFloorSchemaForm } from "./MissingFloorSchemaForm";
import { DrawingPlume } from "../../../components/DrawCanvas/DrawingPlume";
import { EditDrawingPlume } from "../../../components/DrawCanvas/EditCanvas";
import SchemaWithEquipments from "../../../components/DrawCanvas/SchemaWithEquipments";



export const ImageSchema = ({
  control,
  floorScheme,
  setFloorScheme,
  setValue,
  getValues,
  addplume = false,
  setscaleEditRoom,
  resetField,
  turnOnEdit,
  facilityId,
  isDrawPoligon,
  isEquipAvail =false,
  isEditShape,
  selectShape,
  handleSubmit,
  selectedId,
  draggable = false,
  newRoomAddresses,
  setNewRoomAddresses,
  searchIntNum,
  setsearchIntNum,
  xyGroup,
  setxyGroup,
  isLoading,
  $container,
}) => {
  const { floor, roomAddresses } = useWatch({
    control,
  });

  const [widthContainer, setWidthContainer] = useState(0);
  useEffect(() => {
    setWidthContainer($container.current?.offsetWidth);
  }, [$container]);

  useEffect(() => {
    const handleResize = () => {
      setWidthContainer($container.current?.offsetWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { SCHEMA_CANVAS } = useAppSelector((state) => state.settings);
  const { setCanvasSize } = useAction();
  
  const {
    width: screenRelatedWidth,
    height: screenRelatedHeight,
    scale,
  } = useMemo(() => {
    const width = widthContainer;
    const height = widthContainer / (SCHEMA_CANVAS.WIDTH / SCHEMA_CANVAS.HEIGHT);
    return SCHEMA_CANVAS.WIDTH
      ? {
        width,
        height,
        scale: {
          x: width / SCHEMA_CANVAS.WIDTH,
          y:  height / SCHEMA_CANVAS.HEIGHT,
        },
      }
      : {};
  }, [SCHEMA_CANVAS, widthContainer]);
  
  const screenRelatedRoomAddresses = useMemo(
    () =>{
      return getRoomAddressForScheme(roomAddresses, scale)}
      ,
    [roomAddresses, scale]
  );
  const drawScheme = useCallback(async (schemaImg) => {
    await loadImg(schemaImg).then((image) => {
      setCanvasSize({
        WIDTH: image.width,
        HEIGHT: image.height,
      });
    });
  },[]);
  
  useEffect(() => {
    const widthChanged = screenRelatedWidth && screenRelatedWidth !== 0;
    const heightChanged = screenRelatedHeight && screenRelatedHeight !== 0;
    
    if (floorScheme && widthChanged && heightChanged) {
      drawScheme(floorScheme, screenRelatedWidth, screenRelatedHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorScheme, screenRelatedWidth, screenRelatedHeight]);

  if (isLoading) return;
  return floorScheme ? (
    addplume ? (
      <DrawingPlume
        setValue={setValue}
        width={screenRelatedWidth || 0}
        height={screenRelatedHeight || 0}
        control={control}
        schemaImg={floorScheme}
        facilityId={facilityId}
        setsearchIntNum={setsearchIntNum}
        searchIntNum={searchIntNum}
        isDrawPoligon={isDrawPoligon}
        roomAddresses={screenRelatedRoomAddresses}
        getValues={getValues}
        resetField={resetField}
        newRoomAddresses={newRoomAddresses}
        setNewRoomAddresses={setNewRoomAddresses}
      />
    ) : turnOnEdit ?
      <EditDrawingPlume
        handleSubmit={handleSubmit}
        width={screenRelatedWidth || 0}
        height={screenRelatedHeight || 0}
        control={control}
        facilityId={facilityId}
        schemaImg={floorScheme}
        isDrawPoligon={isDrawPoligon}
        isEditShape={isEditShape}
        scaleRoom={scale}
        setsearchIntNum={setsearchIntNum}
        searchIntNum={searchIntNum}
        roomAddresses={screenRelatedRoomAddresses}
        getValues={getValues}
        selectShape={selectShape}
        xyGroup={xyGroup}
        setxyGroup={setxyGroup}
        selectedId={selectedId}
        setValue={setValue}
        resetField={resetField}
        newRoomAddresses={newRoomAddresses}
        setNewRoomAddresses={setNewRoomAddresses}
      />
      :
     

      <SchemaWithEquipments
          name={`${formFields.equipmentsCoordinates}`}
          width={screenRelatedWidth || 0}
          height={screenRelatedHeight || 0}
          xyGroup={xyGroup}
          setxyGroup={setxyGroup}
          isPlan = {true}
          scaleRoom={scale}
          schemaImg={floorScheme}
          roomAddresses={screenRelatedRoomAddresses}
          setValue={setValue}
          control={control}
          draggable={draggable}
        />
  ) : (
    <Box className="plume-image_container-schema">
      <MissingFloorSchemaForm floor={floor} setFloorScheme={setFloorScheme} />
    </Box>
  );
};
