import React, { useState, useEffect, useMemo } from "react";
import {
  Stage,
  Layer,
  Line,
  Circle,
  Group,
  Image,
  Rect,
  Text,
} from "react-konva";
import { Box } from "@mui/material";

import {
  loadImg,
  getRectCoordinates,
  getIsInsidePoint,
} from "../../../helpers/helper";
import { getParamsText } from "../../pages/Plume/ImageSchema/helpers";
import { formFields, modalAddressConfig } from "../../pages/Plume/form-config";

import { SubNotificationPanel } from "../../pages/Plume/ImageSchema/SubNotificationPanel";
import { ModalRoomAddress } from "../Modals/ModalRoomAddress";
import { useAppSelector } from "../../../hooks/redux";
import { useFieldArray } from 'react-hook-form'

const emptyRectParams = {
  x: null,
  y: null,
  width: null,
  height: null,
};

const initialTransform = {
  x: 0,
  y: 0,
  active: false,
};

export const DrawingPlume = ({
  width,
  height,
  control,
  schemaImg,
  facilityId,
  isDrawPoligon,
  roomAddresses,
  getValues,
  setsearchIntNum,
  searchIntNum,
  resetField,
  newRoomAddresses,
  setNewRoomAddresses,
  setValue,
}) => {
  const [image, setImage] = useState(null);
  const [transform, setTransform] = useState(initialTransform);
  const [isFinished, setIsFinished] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedintNum, SetselectedintNum] = useState(null);
  const [points, setPoints] = useState([]);
  const [rectParams, setRectParams] = useState(emptyRectParams);
  const { SCHEMA_CANVAS } = useAppSelector((state) => state.settings);
  const scale = useMemo(
    () => ({
      x: SCHEMA_CANVAS.WIDTH / width,
      y: SCHEMA_CANVAS.HEIGHT / height,
    }),
    [width, height, SCHEMA_CANVAS]
  );
  const screenRelatedNewRoomAddresses = useMemo(() => {
    return newRoomAddresses.map(({ coordinates, ...rest }) => ({
      ...rest,
      coordinates: coordinates.map((item, index) =>
        index % 2 ? item / scale.y : item / scale.x
      ),
    }));
  }, [scale, newRoomAddresses]);

  // ==========
  const getPointPosition = (event) => {
    const { layerX, layerY, changedTouches } = event.evt;
    if (changedTouches) {
      const stage = event.target.getStage().attrs.container;
      const { left, top } = stage.getBoundingClientRect();
      return {
        layerX: changedTouches[0]?.clientX - left,
        layerY: changedTouches[0]?.clientY - top,
      };
    } else {
      return { layerX, layerY };
    }
  };
  
  const { fields: plumes, append: appendPlume, remove: removePlume, update: updatePlume } = useFieldArray({
    control,
    name: formFields.plumes,
  });

  const toStartDraw = (event) => {
    if (isFinished) return;
    const { layerX, layerY } = getPointPosition(event);
    const isInside = getIsInsidePoint({
      x: layerX,
      y: layerY,
      roomAddresses: allRoomAddresses || [],
    });

    if (isInside) return;

    if (isDrawPoligon) {
      const insideX =
        layerX >= points[0]?.x - 10 && layerX <= points[0]?.x + 10;
      const insideY =
        layerY >= points[0]?.y - 10 && layerY <= points[0]?.y + 10;
      const newPoint = {
        x: insideX ? points[0]?.x : layerX,
        y: insideY ? points[0]?.y : layerY,
      };
      if (insideX && insideY) {
        setIsFinished(true);
        setTransform({
          x: newPoint.x,
          y: newPoint.y - 45,
          active: true,
        });
      }

      setPoints([...points, newPoint]);
    } else {
      const newPoint = { x: layerX, y: layerY };
      const { x, y } = rectParams || {};

      if (x === null || y === null) {
        setIsFinished(false);
        setRectParams({ ...newPoint });
      }
    }
  };

  const toMoveDraw = (event) => {
    if (!isFinished) {
      const { layerX, layerY } = getPointPosition(event);
      const { x, y } = rectParams || {};
      if (x && y) {
        const width = layerX - x;
        const height = layerY - y;
        const isInside = getIsInsidePoint({
          x: layerX,
          y: layerY,
          roomAddresses: allRoomAddresses || [],
        });

        if (isInside) return;
        setRectParams({ ...rectParams, width, height });
      }
    }
  };

  const toEndDraw = (event) => {
    if (isDrawPoligon || isFinished) return;
    const { layerX, layerY } = getPointPosition(event);
    const { width: widthRect, height: heightRect } = rectParams || {};
    const isToGoUotFieldCanvas =
      layerY <= 0 || layerY >= height || layerX <= 0 || layerX >= width;

    if (widthRect && heightRect && !isToGoUotFieldCanvas) {
      const newPoint = { x: layerX, y: layerY };
      setIsFinished(true);
      setTransform({
        x: newPoint.x - widthRect,
        y: newPoint.y + 2,
        active: true,
      });
    } else {
      setRectParams(emptyRectParams);
    }
  };

  const handleCloseIcon = () => {
    setTransform(initialTransform);
  
    setPoints([]);
    setRectParams(emptyRectParams);
    setIsFinished(false);
  };

  const handleCheckIcon = () => setOpen(true);
    
  const handleModalSave = (e) => {
    
    const newCoordinates = isDrawPoligon
      ? points?.map((e) => [e.x * scale.x, e.y * scale.y]).flat()
      : getRectCoordinates(rectParams, scale);
    
    const sosao = {
      coordinates: newCoordinates,
      protectionObjectIntegrNumberAltId:selectedintNum,
      plumes: !plumes.find(el => typeof el === 'string') ? plumes.map(el => el.name) : plumes,
     ...getValues(formFields.roomAddress),
    }
    
    setNewRoomAddresses((prev) => [
      ...prev,
      sosao
    ]);
    
    isDrawPoligon ? setPoints([]) : setRectParams(emptyRectParams);
    setTransform(initialTransform);
    setIsFinished(false);
    setValue(formFields.plumes, [])
    handleModalCancel();
  };

  const handleModalCancel = () => {
    handleCloseIcon();
    resetField(formFields.roomAddress);
    setOpen(false);
  };
 
  const style = {
    display: transform.active ? "block" : "none",
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  };

  const allRoomAddresses = useMemo(() => {
    
    return [
      ...roomAddresses?.filter(({ id }) => id),
      ...screenRelatedNewRoomAddresses,
    ];
  }, [roomAddresses, screenRelatedNewRoomAddresses]);

  const drawScheme = async (schemaImg, height, width) => {
 
    await loadImg(schemaImg, height, width).then((image) => setImage(image));
  };
  useEffect(() => {

    schemaImg && drawScheme(schemaImg, height, width);
  }, [schemaImg, height, width]);

  return (
    <Box sx={{ position: "relative", cursor: "crosshair" }}>
      <SubNotificationPanel
        style={style}
        handleCloseIcon={handleCloseIcon}
        handleCheckIcon={handleCheckIcon}
      />
      <ModalRoomAddress
        plumes={plumes}
        appendPlume={appendPlume}
        removePlume={removePlume}
        updatePlume={updatePlume}
        selectedintNum={selectedintNum}
        SetselectedintNum={SetselectedintNum}
        name={formFields.roomAddress}
        control={control}
        open={open}
        setsearchIntNum={setsearchIntNum}
        searchIntNum={searchIntNum}
        facilityId={facilityId}
        config={modalAddressConfig}
        handleSave={(e)=>handleModalSave(e)}
        handleCancel={handleModalCancel}
      />
      <Stage
        width={width}
        height={height}
        onMouseDown={toStartDraw}
        onMouseUp={toEndDraw}
        onMouseMove={toMoveDraw}
        onTouchStart={toStartDraw}
        onTouchEnd={toEndDraw}
        onTouchMove={toMoveDraw}
      >
        <Layer>
          <Image image={image} x={0} y={0} />
          {allRoomAddresses.map(
            ({ id, coordinates, name, plumes }, index) =>
              !!coordinates?.length && (
                <Group key={id || index}>
                  <Line
                    points={coordinates}
                    stroke="red"
                    fill="#f1b8bc80"
                    closed={true}
                  />
                  <Text
                    {...getParamsText({ coordinates, text: name, line: 0 })}
                  />
                  <Text
                    {...getParamsText({ coordinates, text: plumes[0], line: 1 })}
                  />
                </Group>
              )
          )}
          <Group>
            {points?.map((point, index) => (
              <Circle
                key={index}
                x={point.x}
                y={point.y}
                radius={3}
                fill="red"
              />
            ))}
            {isDrawPoligon ? (
              <Line
                points={points?.map((e) => [e.x, e.y]).flat()}
                stroke="red"
                fill="#f1b8bc80"
                closed={isFinished}
              />
            ) : (
              <Rect
                {...rectParams}
                fill={"#f1b8bc80"}
                stroke={"red"}
                strokeWidth={2}
              />
            )}
          </Group>
        </Layer>
      </Stage>
    </Box>
  );
};
