import { Box, Input, Typography, Button } from '@mui/material'
import { TreeItem2Checkbox, TreeItem2Content, TreeItem2GroupTransition, TreeItem2Icon, TreeItem2IconContainer, TreeItem2Label, TreeItem2Provider, TreeItem2Root } from '@mui/x-tree-view'
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2/useTreeItem2'
import React from 'react';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import s from './AddEquipmentLite.module.css';
import { getEquipmentIcon } from '../../../../helpers/getEquipmentIcon'
import Draggable from '../ImageSchema/Draggable'
import { DragOverlay } from '@dnd-kit/core'
import EditIcon from '@mui/icons-material/Edit';

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const { id, itemId, item, label, disabled, children, isGroup, countNonPlaced, countPlaced, equipmentTypeId, equipmentId, deleteEquip,canEdit,editEquip, canDelete, hasPlacement, onAddEquipment, isForEdit, onChangeName, onOpenEditReglament, isKind, placedItems, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)}>
        <TreeItem2Content {...getContentProps()}>
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
            {
              isForEdit && (
                <Input onClick={(e) => {
                  e.stopPropagation();
                }} onChange={(e) => onChangeName(e)} value={label} />
              ) || (
                <TreeItem2Label sx={{alignContent:"center",}} {...getLabelProps()} />
              )
            }
            {
              !!item?.number && (
                <Typography sx={{ alignContent:"center",flexBasis: '15%' }}>{item.number}</Typography>
              )
            }
            {canEdit && <Button
              className="btn btn-editPen btn-basket-small"
              onClick={() => editEquip()}
            >
               <EditIcon />
            </Button>
            }
            {canDelete && <Button
              className="btn btn-remove btn-basket-small"
              onClick={() => deleteEquip()}
            >
              <DeleteForeverIcon />
            </Button>
            }
            {
              equipmentTypeId && !hasPlacement && !placedItems.includes(equipmentId) && (
                <Draggable
                  withOverlay
                  className="schema-field-menu-items-row-icon"
                  id={itemId}
                  type={"fire"}
                >
                  <img src={getEquipmentIcon(equipmentTypeId)} />
                </Draggable>
              )
            }
            {isGroup && <Box sx={{ display: 'flex', }}>
              <Typography sx={{ color: "green" }}>{countPlaced}</Typography>
              <Typography>/</Typography>
              <Typography sx={{ color: "red" }}>{countNonPlaced}</Typography>
            </Box>
            }
            {
              !equipmentId && (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', height: "25px", width: "11px", justifyContent: 'center', backgroundColor: '#FFF', padding: '0 7px', borderRadius: 1, border: '1px solid #f0f0f0' }}
                  onClick={(e) => {
                    onAddEquipment();
                  }}
                >
                  +
                </Box>
              )
            }
            {isGroup && <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}
              onClick={(e) => {
                isGroup && onOpenEditReglament();
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <SettingsOutlinedIcon sx={{ width: "18px", height: "18px" }} />
            </Box>}
          </Box>
        </TreeItem2Content>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export default CustomTreeItem